/** @jsxRuntime classic /
/* @jsx jsx */
import { jsx } from '@emotion/react';
import facepaint from 'facepaint';
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { languageState, text } from '../../utils/state';
import logo from '../../media/logo_xlr_white.svg';
import minus from '../../media/minus_white.png';

// RESPONSIVENESS SETTINGS
const breakpoints = [ 600, 800, 1300, 1800 ];
const mq = facepaint(breakpoints.map((bp) => `@media (min-width: ${bp}px)`));

export default function Header(props) {
	const setLanguageRecoil = useSetRecoilState(languageState);
	const languageRecoil = useRecoilValue(languageState);
	const textRecoil = useRecoilValue(text);

	const [ openMenu, setOpenMenu ] = useState(false);

	useEffect(
		() => {
			setOpenMenu(false);
		},
		[ props ]
	);

	return [
		<div css={props.location.pathname === '/xlr+' ? navbar2 : navbar} className="navbar" key="desktop">
			<NavLink className="Sans90" to="/" css={logoContStyle}>
				<img
					src={logo}
					alt="xlr"
					css={props.location.pathname === '/xlr+' ? logoStyle2 : logoStyle}
					className="rotate90"
				/>
			</NavLink>
			<div className="menu">
				<NavLink className={`Sans90 ${props.location.pathname === '/xlr+' ? '_xlr2' : '_xlr'}`} to="/about">
					{textRecoil[languageRecoil].navbar[1].toUpperCase()}
				</NavLink>
				<NavLink className={`Sans90 ${props.location.pathname === '/xlr+' ? '_xlr2' : '_xlr'}`} to="/home">
					{textRecoil[languageRecoil].navbar[2].toUpperCase()}
				</NavLink>
				<a
					className={`Sans90 ${props.location.pathname === '/xlr+' ? '_xlr2' : '_xlr'}`}
					onClick={() => setOpenMenu(!openMenu)}
					href="mailto: info@xlrestudio.com"
					target="_blank"
					rel="noreferrer"
				>
					{textRecoil[languageRecoil].navbar[3].toUpperCase()}
				</a>
			</div>
			<Toggle
				{...props}
				changeLanguage={() => setLanguageRecoil(languageRecoil === 'spanish' ? 'english' : 'spanish')}
				languageRecoil={languageRecoil}
			/>
		</div>,
		<div css={mobileNavbar} className="navbar" key="mobile">
			<NavLink className="Sans90" to="/" css={mobileLogoCont}>
				<img
					css={mobileLogo}
					src={logo}
					alt="xlr"
					style={{
						filter: props.location.pathname === '/xlr+' && !openMenu ? '' : 'invert(100%)'
					}}
				/>
			</NavLink>
			{!openMenu ? (
				<div css={menuLogoWrapper}>
					<img
						alt="svgImg"
						src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iMjQiIGhlaWdodD0iMjQiCnZpZXdCb3g9IjAgMCAxNzIgMTcyIgpzdHlsZT0iIGZpbGw6IzAwMDAwMDsiPjxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0ibm9uZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIHN0cm9rZS1saW5lY2FwPSJidXR0IiBzdHJva2UtbGluZWpvaW49Im1pdGVyIiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHN0cm9rZS1kYXNoYXJyYXk9IiIgc3Ryb2tlLWRhc2hvZmZzZXQ9IjAiIGZvbnQtZmFtaWx5PSJub25lIiBmb250LXdlaWdodD0ibm9uZSIgZm9udC1zaXplPSJub25lIiB0ZXh0LWFuY2hvcj0ibm9uZSIgc3R5bGU9Im1peC1ibGVuZC1tb2RlOiBub3JtYWwiPjxwYXRoIGQ9Ik0wLDE3MnYtMTcyaDE3MnYxNzJ6IiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9Im5vbnplcm8iPjwvcGF0aD48ZyBmaWxsPSIjZmZmZmZmIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik03OC44MzMzMywxNC4zMzMzM3Y2NC41aC02NC41djE0LjMzMzMzaDY0LjV2NjQuNWgxNC4zMzMzM3YtNjQuNWg2NC41di0xNC4zMzMzM2gtNjQuNXYtNjQuNXoiPjwvcGF0aD48L2c+PC9nPjwvc3ZnPg=="
						css={props.location.pathname === '/xlr+' && plusBlack}
						onClick={() => setOpenMenu(!openMenu)}
					/>
				</div>
			) : (
				<div css={menuLogoWrapper}>
					<img src={minus} alt="expand" onClick={() => setOpenMenu(!openMenu)} />
				</div>
			)}

			<div css={mobileMenu} style={{ display: openMenu ? 'flex' : 'none' }}>
				<NavLink className="Sans90" to="/about" onClick={() => setOpenMenu(!openMenu)}>
					{textRecoil[languageRecoil].navbar[1].toUpperCase()}
				</NavLink>
				<NavLink className="Sans90" to="/home" onClick={() => setOpenMenu(!openMenu)}>
					{textRecoil[languageRecoil].navbar[2].toUpperCase()}
				</NavLink>
				<a
					className="Sans90"
					onClick={() => setOpenMenu(!openMenu)}
					href="mailto: info@xlrestudio.com"
					target="_blank"
					rel="noreferrer"
				>
					{textRecoil[languageRecoil].navbar[3].toUpperCase()}
				</a>
				<Toggle
					rotate={false}
					{...props}
					changeLanguage={() => setLanguageRecoil(languageRecoil === 'spanish' ? 'english' : 'spanish')}
					languageRecoil={languageRecoil}
				/>
			</div>
		</div>
	];
}

const Toggle = (props) => {
	return (
		<div
			css={toggle}
			onClick={() => props.changeLanguage()}
			style={{
				color: props.location.pathname === '/xlr+' ? '#ddffc9' : 'black',
				backgroundColor: props.location.pathname === '/xlr+' ? 'black' : 'white'
			}}
		>
			<span
				className={`${props.rotate ? 'rotate90' : null}`}
				style={{ opacity: props.languageRecoil === 'spanish' ? 1 : 0 }}
			>
				EN
			</span>
			<span
				className={`${props.rotate ? 'rotate90' : null}`}
				style={{ opacity: props.languageRecoil === 'spanish' ? 0 : 1 }}
			>
				ES
			</span>
		</div>
	);
};

// Minus sign
// <img src="https://img.icons8.com/material-outlined/24/000000/minus.png"/>

const navbar = mq({
	transition: 'all linear 200ms',
	WebkitTransition: '0.4s',
	position: 'fixed',
	zIndex: '20',
	top: '0',
	left: '0',
	backgroundColor: 'black',
	color: 'white',
	minHeight: '100vh',
	width: '70px',
	display: [ 'none', 'none', 'flex' ],
	flexDirection: 'column-reverse',
	justifyContent: 'space-around',
	alignItems: 'center',
	borderRight: '1px solid white',
	'*': {
		transform: 'rotate(180deg)',
		writingMode: 'vertical-rl'
	},
	'.menu': {
		display: 'grid',
		gridTemplateColumns: '33% 33% 33%',
		alignItems: 'center',
		height: '70vh',
		'a, div': {
			fontSize: '18px',
			transform: 'rotate(0deg)',
			color: 'white',
			textDecoration: 'none',
			textAlign: 'center',
			height: '100%',
			transition: 'all linear 200ms',
			WebkitTransition: '0.4s',
			border: 'none',
			borderRadius: '100px',
			padding: '0 5px 0 5px'
		}
	}
});

const navbar2 = mq({
	transition: 'all linear 200ms',
	WebkitTransition: '0.4s',
	position: 'fixed',
	zIndex: '20',
	top: '0',
	left: '0',
	backgroundColor: '#ddffc9',
	minHeight: '100vh',
	width: '70px',
	display: [ 'none', 'none', 'flex' ],
	flexDirection: 'column-reverse',
	justifyContent: 'space-around',
	alignItems: 'center',
	borderRight: '1px solid black',
	'*': {
		transform: 'rotate(180deg)',
		writingMode: 'vertical-rl'
	},
	'.menu': {
		display: 'grid',
		gridTemplateColumns: '27% 27% 27% 19%',
		alignItems: 'center',
		height: '70vh',
		marginBottom: '-3vh',
		boxSizing: 'border-box',
		a: {
			fontSize: '18px',
			transform: 'rotate(0deg)',
			color: 'black',
			textDecoration: 'none',
			textAlign: 'center',
			height: '100%',
			transition: 'all linear 200ms',
			WebkitTransition: '0.4s',
			border: 'none',
			borderRadius: '100px',
			padding: '0 5px 0 5px'
		}
	}
});

const logoContStyle = {
	width: '100%',
	height: '10vh',
	boxSizing: 'border-box',
	marginBottom: '1rem'
};

const logoStyle = {
	filter: 'invert(100%)',
	width: '100%'
};

const logoStyle2 = {
	width: '100%'
};

// MOBILE
const mobileNavbar = mq({
	display: [ 'flex', 'flex', 'none' ],
	position: 'fixed',
	top: '0',
	left: '0',
	minWidth: '100vw',
	flexDirection: 'column',
	zIndex: 50,
	backgroundColor: 'lightblue'
});

const mobileLogoCont = {
	position: 'absolute',
	width: '20vw',
	height: 'auto',
	top: '10px',
	left: '5vw',
	zIndex: 101
};

const mobileLogo = mq({
	position: 'relative',
	width: '100%',
	outline: 'none'
});

const menuLogoWrapper = mq({
	position: 'absolute',
	justifySelf: 'flex-end',
	zIndex: 101,
	top: [ '20px', '30px' ],
	// top: "30px",
	right: '7vw',
	width: '10vw',
	img: {
		position: 'relative',
		width: '100%',
		cursor: 'pointer'
	}
});

const plusBlack = {
	filter: 'invert(100%)'
};

const mobileMenu = {
	position: 'absolute',
	top: 0,
	left: 0,
	backgroundColor: 'black',
	height: '100vh',
	width: '100vw',
	display: 'flex',
	alignItems: 'center',
	flexDirection: 'column',
	justifyContent: 'center',
	zIndex: 100,
	a: {
		color: 'white',
		textDecoration: 'none',
		fontSize: '2rem',
		margin: '2rem 0',
		padding: '1rem 2rem'
	}
};

const toggle = mq({
	borderRadius: '100px',
	width: '30px',
	height: '30px',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	cursor: 'pointer',
	transition: 'all linear 200ms',
	transform: [ 'scale(1.6)', 'scale(1.6)', 'scale(1)' ],
	span: {
		margin: '0 0.1em 0 0',
		fontSize: '14px',
		position: 'absolute',
		transition: 'all linear 200ms'
	}
});
