import { atom } from 'recoil'

export const languageState = atom({
  key: 'languageState',
  default: 'spanish'
})

export const text = atom({
  key: 'text',
  default: {
    english: {
      navbar: {
        1: 'About',
        2: 'Projects',
        3: 'Contact'
      },
      about: {
        title_1:
          'We are a team of designers and specialists in technological innovation, with extensive experience in the field of events and artistic experimentation.',
        '1_1':
          'Our work ranges from the conceptual creation of the project, to the design of the scenography, lighting design, visual content, prototyping, technical drawings and construction of scenographic machinery and elements.',
        '1_2':
          'The innovative use of available resources, as well as the creation of new elements adapted to a project, makes each of our creations unique.',
        title_2: 'Show design',
        '2_1':
          'We produce partial or global design experiences for the performing arts.',
        '2_2':
          'Our work covers the entire process of creating a show: concept, set design, lighting design, visual content, prototyping, technical drawings and the construction of scenographic elements and machinery.',
        '2_3':
          'Communication with the client, the innovative use of technological resources, as well as the in-house creation of new components adapted to a project or an artist, make each of our creations singular and unique.',
        title_3: 'Technical production',
        '3_1':
          'We also provide audiovisual technical services for different types of events. We have permanent staff in cultural centres, museums and institutions.',
        '3_2': 'Services:',
        '3_3': '-Technical direction and coordination',
        '3_4': '-Management of qualified audiovisual technical staff',
        '3_5': '-Technical consultancy',
        '3_6': '-Installation of audiovisual technical equipment',
        '3_7': '-Streaming services',
        title_4: 'Clients',
        button_title: 'Go to projects',
        title_5: 'Team',
        team1: 'CEO Lighting & Set Design',
        team2: 'Technical Coordinator',
        team3: 'Event Producer',
        team4: 'Designer & Art Director',
        team5: 'Administration',
        title_6: 'Location'
      },
      xlr: {
        title_1: 'About XLR+',
        top_1: 'XLR+ is the Experience Design team of XLR.',
        top_2:
          'We help our customers to deliver world-class experiences created through a research-based and user-centric approach.',
        top_3:
          'Together, we will create the vision of the experience you want to put in place and elaborate a human-centred strategy map to get there. Indeed, being human is the key.',
        title_2: 'Our services',
        services_1: 'UX design',
        services_2: 'Brand design',
        services_3: 'Service design',
        services_4: 'Visual design',
        services_5: 'Design research',
        services_6: 'SEO strategy',
        services_7: 'Content strategy',
        services_8: 'UX multilingual copywriting',
        services_9: 'Creative storytelling',
        title_3: 'Our Philosophy',
        card1_title: 'Growth',
        card1_body:
          'We believe that everything has the potential to be greater',
        card2_title: 'Trust',
        card2_body: 'The most important aspect of collaborative work',
        card3_title: 'Diverse',
        card3_body:
          'How any organisation should act in order to deliver exceptional results',
        contact_title: 'Want to know more?',
        contact_btn: 'Contact us!'
      },
      footer: {
        project: 'Have a project?',
        talk: "Let's talk!",
        based: 'Where are we based?',
        address_1: 'Paseo de los',
        address_2: 'Melancólicos, 9, 3ºB',
        address_3: '28005 Madrid',
        consulting: '© XLR Estudio 2021',
        cookies: 'Press'
      },
      projects: {
        title_1: 'Projects',
        card1: 'Client',
        card2: 'Location',
        card3: 'Year'
      }
    },
    spanish: {
      navbar: {
        1: 'Nosotros',
        2: 'Proyectos',
        3: 'Contacto'
      },
      about: {
        title_1:
          'Somos un equipo formado por diseñadores y especialistas en innovación tecnológica, con dilatada labor en el ámbito de los eventos y la experimentación artística',
        '1_1': '',
        '1_2': '',
        title_2: 'Diseño de espectáculos',
        '2_1':
          'Producimos experiencias de diseño parciales o globales para las artes escénicas.',
        '2_2':
          'Nuestro trabajo abarca todo el proceso de creación de un espectáculo: el concepto del proyecto, el diseño de escenografía, el diseño de iluminación, los contenidos visuales, el prototipado, la planimetría y la construcción de elementos o de maquinaria escenográfica.',
        '2_3':
          'La comunicación con el cliente, el uso innovador de los recursos tecnológicos, así como la creación propia de nuevos componentes adaptados a un proyecto o a un artista, hace que cada una de nuestras creaciones sea singular y única.',
        title_3: 'Producción técnica',
        '3_1':
          'Asimismo realizamos servicio técnico audiovisual para distintos tipos de eventos. También contamos con personal estable en centros culturales, museos e instituciones.',
        '3_2': 'Acciones:',
        '3_3': '-Dirección y coordinación técnica',
        '3_4': '-Dirección de personal técnico audiovisual cualificado',
        '3_5': '-Consultoría técnica',
        '3_6': '-Instalación de equipamientos técnicos audiovisuales',
        '3_7': '-Realización de streaming',
        title_4: 'Clientes',
        button_title: 'Ver proyectos',
        title_5: 'Equipo',
        team1: 'CEO Diseño de Iluminación',
        team2: 'Coordinador Técnico',
        team3: 'Producción de Eventos',
        team4: 'Diseño y Dirección de Arte',
        team5: 'Administración',
        title_6: 'Localización'
      },
      xlr: {
        title_1: 'About XLR+',
        top_1: 'XLR+ es el equipo de Experience Design de XLR.',
        top_2:
          'Ayudamos a nuestros clientes a ofrecer experiencias creadas a través de un enfoque centrado en el usuario y basado en la investigación.',
        top_3:
          'Con una sólida experiencia en estrategia digital, nuestro trabajo se basa en crear, lanzar e interactuar con productos, servicios y marcas. Fomentamos mejores conexiones dentro de las organizaciones, entre marcas y personas, entre las personas y la tecnología.',
        title_2: 'Servicios',
        services_1: 'Diseño UX',
        services_2: 'Diseño de marcas',
        services_3: 'Diseño de servicios',
        services_5: 'Diseño visual',
        services_6: 'Investigación de diseño',
        services_7: 'Estrategia SEO',
        services_8: 'Estrategia de contenidos',
        services_9: 'Copywriting UX multilingüe',
        services_10: 'Narrativas Creativas',
        title_3: 'Nuestra filosofía',
        card1_title: 'Crecimiento',
        card1_body:
          'Creemos en el potencial inherente que tienen las cosas para poder llegar más allá',
        card2_title: 'Confianza',
        card2_body:
          'Es el aspecto más importante del trabajo colaborativo en cualquier campo',
        card3_title: 'Personas',
        card3_body:
          'Creamos productos y servicios digitales que mejoran la vida de las personas',
        contact_title: '¿Quieres que te contemos más?',
        contact_btn: 'Escríbenos'
      },
      footer: {
        project: '¿Tienes un proyecto?',
        talk: '¡Hablemos!',
        based: '¿Dónde estamos?',
        address_1: 'Paseo de los',
        address_2: 'Melancólicos, 9, 3ºB',
        address_3: '28005 Madrid',
        consulting: '© XLR Estudio 2021',
        cookies: 'Prensa'
      },
      projects: {
        title_1: 'Proyectos',
        card1: 'Cliente',
        card2: 'Lugar',
        card3: 'Año'
      }
    }
  }
})
