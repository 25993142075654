import { atom } from 'recoil'
import foto1_1 from '../media/projects/project1/foto1_1.webp'
import foto1_2 from '../media/projects/project1/foto1_2.webp'
import foto1_3 from '../media/projects/project1/foto1_3.webp'
import foto1_4 from '../media/projects/project1/foto1_4.webp'
import foto2_1 from '../media/projects/project2/foto2_1.webp'
import foto2_2 from '../media/projects/project2/foto2_2.webp'
import foto2_3 from '../media/projects/project2/foto2_3.webp'
import foto2_4 from '../media/projects/project2/foto2_4.webp'
import foto3_1 from '../media/projects/project3/foto3_1.webp'
import foto3_2 from '../media/projects/project3/foto3_2.webp'
import foto3_3 from '../media/projects/project3/foto3_3.webp'
import foto3_4 from '../media/projects/project3/foto3_4.webp'
import foto4_1 from '../media/projects/project4/foto4_1.webp'
import foto4_2 from '../media/projects/project4/foto4_2.webp'
import foto4_3 from '../media/projects/project4/foto4_3.webp'
import foto4_4 from '../media/projects/project4/foto4_4.webp'
import foto5_1 from '../media/projects/project5/foto5_1.webp'
import foto5_2 from '../media/projects/project5/foto5_2.webp'
import foto5_3 from '../media/projects/project5/foto5_3.webp'
import foto5_4 from '../media/projects/project5/foto5_4.webp'
import foto6_1 from '../media/projects/project6/foto6_1.webp'
import foto6_2 from '../media/projects/project6/foto6_2.webp'
import foto6_3 from '../media/projects/project6/foto6_3.webp'
import foto6_4 from '../media/projects/project6/foto6_4.webp'
import foto8_1 from '../media/projects/project8/foto8_1.webp'
import foto8_2 from '../media/projects/project8/foto8_2.webp'
import foto8_3 from '../media/projects/project8/foto8_3.webp'
import foto8_4 from '../media/projects/project8/foto8_4.webp'
import foto9_1 from '../media/projects/project9/foto9_1.webp'
import foto9_2 from '../media/projects/project9/foto9_2.webp'
import foto9_3 from '../media/projects/project9/foto9_3.webp'
import foto9_4 from '../media/projects/project9/foto9_4.webp'
import foto10_1 from '../media/projects/project10/foto10_1.webp'
import foto10_2 from '../media/projects/project10/foto10_2.webp'
import foto10_3 from '../media/projects/project10/foto10_3.webp'
import foto11_1 from '../media/projects/project11/foto11_1.webp'
import foto11_2 from '../media/projects/project11/foto11_2.webp'
import foto11_3 from '../media/projects/project11/foto11_3.webp'
import foto11_4 from '../media/projects/project11/foto11_4.webp'
import foto12_1 from '../media/projects/project12/foto12_1.jpg'
import foto12_2 from '../media/projects/project12/foto12_2.jpg'
import foto12_3 from '../media/projects/project12/foto12_3.jpg'
import foto12_4 from '../media/projects/project12/foto12_4.jpg'
import foto13_1 from '../media/projects/project13/foto13_1.jpg'
import foto13_2 from '../media/projects/project13/foto13_2.jpg'
import foto13_3 from '../media/projects/project13/foto13_3.jpg'
import foto13_4 from '../media/projects/project13/foto13_4.jpg'
import foto14_1 from '../media/projects/project14/foto14_1.jpg'
import foto14_2 from '../media/projects/project14/foto14_2.jpg'
import foto14_3 from '../media/projects/project14/foto14_3.jpg'
import foto14_4 from '../media/projects/project14/foto14_4.jpg'
import foto15_1 from '../media/projects/project15/foto15_1.jpg'
import foto15_2 from '../media/projects/project15/foto15_2.jpg'
import foto15_3 from '../media/projects/project15/foto15_3.jpg'
import foto15_4 from '../media/projects/project15/foto15_4.jpg'
import foto16_1 from '../media/projects/project16/foto16_1.jpg'
import foto16_2 from '../media/projects/project16/foto16_2.jpg'
import foto16_3 from '../media/projects/project16/foto16_3.jpg'
import foto16_4 from '../media/projects/project16/foto16_4.jpg'
import foto17_1 from '../media/projects/project17/foto17_1.jpg'
import foto17_2 from '../media/projects/project17/foto17_2.jpg'
import foto17_3 from '../media/projects/project17/foto17_3.JPG'
import foto17_4 from '../media/projects/project17/foto17_4.jpg'
import foto18_1 from '../media/projects/project18/foto18_1.jpg'
import foto18_2 from '../media/projects/project18/foto18_2.jpg'
import foto18_3 from '../media/projects/project18/foto18_3.jpg'
import foto18_4 from '../media/projects/project18/foto18_4.jpg'
import foto19_1 from '../media/projects/project19/foto19_1.jpg'
import foto19_2 from '../media/projects/project19/foto19_2.jpg'
import foto19_3 from '../media/projects/project19/foto19_3.jpg'
import foto19_4 from '../media/projects/project19/foto19_4.jpg'
import foto19_5 from '../media/projects/project19/foto19_5.jpg'
import foto20_1 from '../media/projects/project20/foto20_1.png'
import foto20_2 from '../media/projects/project20/foto20_2.png'
import foto20_3 from '../media/projects/project20/foto20_3.png'
import foto20_4 from '../media/projects/project20/foto20_4.png'
import foto21_1 from '../media/projects/project21/foto21_1.jpg'
import foto21_2 from '../media/projects/project21/foto21_2.jpg'
import foto21_3 from '../media/projects/project21/foto21_3.jpg'
import foto21_4 from '../media/projects/project21/foto21_4.jpg'
import foto22_1 from '../media/projects/project22/foto22_1.png'
import foto22_2 from '../media/projects/project22/foto22_2.png'
import foto22_3 from '../media/projects/project22/foto22_3.png'
import foto22_4 from '../media/projects/project22/foto22_4.png'
import foto22_5 from '../media/projects/project22/foto22_5.png'
import foto22_6 from '../media/projects/project22/foto22_6.png'

export const projectsStateES = atom({
  key: 'projectsStateES',
  default: [
    {
      title: 'José Luis Perales – Balada para una Despedida',
      client: 'Berry Navarro Producciones',
      location: 'Grandes recintos y Teatros en España y América',
      year: '2021',
      description:
        'Dirección de Escenografía y Artes visuales: Susana Martínez Allende / Diseño de Espacio escénico e Iluminación: Maxi Gilbert',
      credits: 'Foto: Laura Voskian',
      images: [foto1_1, foto1_2, foto1_3, foto1_4],
    },
    {
      title: 'Jorge Drexler – Silente',
      client: '8 Silabas',
      location: 'Teatros en España y Sudamérica',
      year: '2019',
      description: 'Diseño de Puesta en Escena, Escenografía e iluminación',
      credits: 'Foto: Jesús Cornejo',
      images: [foto2_1, foto2_2, foto2_3, foto2_4],
      video:
        'https://www.youtube.com/watch?v=Y5o9qGBCUpY&list=PL-7FYtv6JtX62jk8s8OUsidimu3EXPSaL&index=12',
    },
    {
      title: 'Amaral – Salto al Color',
      client: 'Gatorama',
      location: 'Grandes recintos en España',
      year: '2019',
      description:
        'Dirección de Artes visuales: Susana Martínez Allende;Diseño de Escenografía e iluminación: Maxi Gilbert',
      credits: 'Foto: Dana Balajovsky',
      images: [foto3_1, foto3_2, foto3_3, foto3_4],
      video: 'https://www.youtube.com/watch?v=ZFE5xIzCclg',
    },
    {
      title: 'Alejandro Sanz – La Gira',
      client: 'MOW Management',
      location: 'Estadios en España y América',
      year: '2019',
      description: 'Diseño de Escenografía, iluminación y contenidos visuales',
      credits: 'Foto: XLR',
      images: [foto4_1, foto4_2, foto4_3, foto4_4],
      video: 'https://www.youtube.com/watch?v=5Hf08IPgDtU',
    },
    {
      title: 'Los 40 Music Awards 2019',
      client: 'Planet Events',
      location: 'Wizink Center Madrid',
      year: '2019',
      description: 'Diseño de Escenografía e iluminación',
      credits: 'Foto: Laura Voskian',
      images: [foto5_1, foto5_2, foto5_3, foto5_4],
      vidoe: 'https://www.youtube.com/watch?v=Gj3kWeR4b8o',
    },
    {
      title: 'Vetusta Morla – Mismo Sitio Distinto Lugar',
      client: 'Pequeño Salto Mortal',
      location: 'Grandes recintos y Festivales en España',
      year: '2018',
      description: 'Diseño de puesta en escena, escenografía e iluminación',
      credits: 'Foto: María Macías',
      images: [foto6_1, foto6_2, foto6_3, foto6_4],
      video: 'https://www.youtube.com/watch?v=Z5CJaE2pIDk',
    },
    {
      title: 'Sidonie – El Peor Grupo del Mundo',
      client: 'Emerge',
      location: 'Salas de conciertos y Festivales en España',
      year: '2017',
      description: 'Diseño de Escenografía e iluminación',
      credits: 'Foto: África Mess',
      images: [foto8_1, foto8_2, foto8_3, foto8_4],
    },
    {
      title: 'León Benavente – 2',
      client: 'Ser Brigada / Emerge',
      location: 'Sala de conciertos y Festivales en España',
      year: '2017',
      description: 'Diseño de Escenografía e iluminación',
      credits: 'Foto: Dani Kalamar',
      images: [foto9_1, foto9_2, foto9_3, foto9_4],
      video: 'https://www.youtube.com/watch?v=-sT-hAynGC0',
    },
    {
      title: 'Leiva – Monstruos',
      client: 'Attraction',
      location: 'Salas de conciertos, Grandes recintos y Festivales en España',
      year: '2016',
      description: 'Diseño de Escenografía e iluminación',
      credits: 'Foto: Wilma Lorenzo',
      images: [foto10_1, foto10_2, foto10_3],
      video: 'https://www.youtube.com/watch?v=7V4kqSC3gqg',
    },
    {
      title: 'Amaral – Nocturnal',
      client: 'Gatorama',
      location: 'Grandes recintos y Festivales en España',
      year: '2016',
      description:
        'Diseño de puesta en escena, escenografía, iluminación y visuales',
      credits: 'Foto: XLR',
      images: [foto11_1, foto11_2, foto11_3, foto11_4],
      video: 'https://www.youtube.com/watch?v=zR4fJ5gJHTs',
    },
    {
      title: 'Vetusta Morla – La Deriva',
      client: 'Pequeño Salto Mortal',
      location: 'Salas de concierto, Grandes recintos y Festivales en España',
      year: '2014 - 2015',
      description: 'Diseño de puesta en escena, escenografía e iluminación',
      credits: 'Foto: Alfredo Arias / Raúl Murciego',
      images: [foto12_1, foto12_2, foto12_3, foto12_4],
      video: 'https://www.youtube.com/watch?v=0meu-MVojnc',
    },
    {
      title: 'Davidelfin 2002 - 2016',
      client: 'Fourquet 17',
      location: 'Pasarela Cibeles, New York Fashion Week',
      year: '2002 - 2016',
      description:
        'Dirección de Producción Técnica;Diseño de iluminación;Asesoría Escenotécnica',
      credits: '',
      images: [foto13_1, foto13_2, foto13_3, foto13_4],
      video: '',
    },
    {
      title: 'La Noche en Blanco 2007 - 2010',
      client: 'Madrid Arte y Cultura',
      location: 'Varios recintos de Madrid',
      year: '2007 - 2012',
      description: 'Dirección de Producción;Dirección Técnica',
      credits: '',
      images: [foto14_1, foto14_2, foto14_3, foto14_4],
      video: '',
    },
    {
      title: 'Centenario de la Gran Vía',
      client: 'Madrid Arte y Cultura',
      location: 'Varios recintos de Madrid',
      year: '2010',
      description: 'Dirección de Producción;Dirección Técnica',
      credits: 'Foto: Andrés de Gabriel',
      images: [foto15_1, foto15_2, foto15_3, foto15_4],
      video: '',
    },
    {
      title: 'Russian Red',
      client: 'Charco Música',
      location: 'Salas de concierto, Grandes recintos y Festivales en España',
      year: '2012, 2014, 2016 y 2019',
      description: 'Diseño de Puesta en escena, escenografía e iluminación',
      credits: '',
      images: [foto16_1, foto16_2, foto16_3, foto16_4],
      video: '',
    },
    {
      title: 'Hermès - La esencia del cuero',
      client: 'Hermès International',
      location: 'Galería de Cristal, Palacio de Cibeles, Madrid',
      year: '2013',
      description: 'Dirección de Producción Técnica;Exposición',
      credits: 'Foto: Íñigo de Amescua',
      images: [foto17_1, foto17_2, foto17_3, foto17_4],
      video: '',
    },
    {
      title: 'Veranos de la Villa 2016-2019',
      client: 'Madrid Destino',
      location: 'Varios recintos Madrid',
      year: '2016 - 2019',
      description: 'Diseño de Espacio Escénico e iluminación',
      credits: 'Foto: Estudio Perplejo',
      images: [foto18_1, foto18_2, foto18_3, foto18_4],
      video: '',
    },
    {
      title: 'Clausura del Bicentenario del Museo Nacional Del Prado',
      client: 'Acciona Producciones y Diseño',
      location: 'Museo Nacional Del Prado, Madrid',
      year: '2019',
      description:
        'Creación de Espectáculo;Dirección de Artes Visuales: Susana Martínez Allende;Música Compuesta, producida e interpretada: Olivier Arson;Diseño de Espacio escénico, Iluminación y Láser: Maxi Gilbert',
      credits: 'Foto: Dana Balajovsky',
      images: [foto19_1, foto19_2, foto19_3, foto19_4, foto19_5],
      video: '',
    },
    {
      title: 'Planum et Lumen - Maxi Gilbert',
      client: 'Madrid Destino',
      location: 'Jardines del Palacio de Buenavista, Madrid',
      year: '2021',
      description:
        'Instalación creada por*https://maxigilbert.com*Maxi Gilbert*para el Festival LuzMadrid',
      credits: 'Foto: Laura Voskian',
      images: [foto20_1, foto20_2, foto20_3, foto20_4],
      video: '',
    },
    {
      title: 'Robe - Mayéutica',
      client: 'El Dromedario Records',
      location: 'Grandes recintos en España, Gira',
      year: '2021 - 2022',
      description: 'Diseño de Escenografía e Iluminación: Maxi Gilbert',
      credits: 'Foto: Dana Balajovsky',
      images: [foto21_1, foto21_2, foto21_3, foto21_4],
      video: '',
    },
    {
      title: 'Manuel Carrasco - Hay que vivir el Momento',
      client: 'Riff Producciones',
      location: 'Varios recintos España',
      year: '2022',
      description:
        'Diseño de Escenografía y Artes Visuales: Susana Martínez Allende /; Diseño de Espacio Escénico e Iluminación: Maxi Gilbert',
      credits: 'Foto: Javier Rosa',
      images: [foto22_1, foto22_2, foto22_3, foto22_4, foto22_5, foto22_6],
      video: '',
    },
  ],
})
