/** @jsxRuntime classic /
/* @jsx jsx */
import { jsx } from '@emotion/react'
import facepaint from 'facepaint'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { Carousel } from 'react-responsive-carousel'
import LazyLoad from 'react-lazyload'

// RESPONSIVENESS SETTINGS
const breakpoints = [ 600, 800, 1300, 1800 ]
const mq = facepaint(breakpoints.map((bp) => `@media (min-width: ${bp}px)`))

export default function CarouselComp({ images }) {
	return (
		<Carousel showIndicators={false} showThumbs={false} dynamicHeight={false} showStatus={false}>
			{images.map((image, i) => {
				return (
					<div key={i} css={mediaWrapper} onClick={(e) => e.stopPropagation()}>
						<LazyLoad height='100%'>
							<img loading='lazy' src={image} alt='project overview' />
						</LazyLoad>
					</div>
				)
			})}
		</Carousel>
	)
}

const mediaWrapper = mq({
	height: [ '350px', '472px', '472px' ],
	width: '100%',
	marginBottom: '24px',
	'*': {
		height: '100%'
	},
	img: {
		borderRadius: '8px',
		height: '100%',
		objectFit: 'cover'
	}
})
