/** @jsxRuntime classic /
/* @jsx jsx */
import { jsx } from '@emotion/react';
import facepaint from 'facepaint';
import instagram from '../../media/instagram.svg';
import linkedin from '../../media/linkedin.svg';
import { useRecoilValue } from 'recoil';
import { languageState, text } from '../../utils/state';
import pdf from '../../media/press.pdf';

// RESPONSIVENESS SETTINGS
const breakpoints = [ 600, 800, 1300, 1800 ];
const mq = facepaint(breakpoints.map((bp) => `@media (min-width: ${bp}px)`));

export default function Footer() {
	const languageRecoil = useRecoilValue(languageState);
	const textRecoil = useRecoilValue(text);

	return (
		<div css={footerStyle}>
			<div className="left_foot" />
			<div className="right_foot">
				<a className="link" href="https://goo.gl/maps/1JZwTh4R1me1CfZF9" target="_blank" rel="noreferrer">
					<p className="Sans60">{textRecoil[languageRecoil].footer.address_1}</p>
					<p className="Sans60">{textRecoil[languageRecoil].footer.address_2}</p>
					<p className="Sans60">{textRecoil[languageRecoil].footer.address_3}</p>
				</a>
			</div>
			<div className="bottom">
				<div className="last_foot">
					<span>{textRecoil[languageRecoil].footer.consulting}</span>
					<a href={pdf} target="_blank" rel="noopener noreferrer">
						{textRecoil[languageRecoil].footer.cookies}
					</a>
				</div>
				<div className="boxes">
					<a target="_blank" href="https://www.instagram.com/xlr_estudio/" rel="noreferrer">
						<img src={instagram} alt="media link instagram" />
					</a>
					<a target="_blank" href="https://www.linkedin.com/company/xlrproducciones/about/" rel="noreferrer">
						<img src={linkedin} alt="media link linkedin" />
					</a>
				</div>
			</div>
		</div>
	);
}

const footerStyle = mq({
	width: [ '100vw', '100vw', 'calc(100vw - 70px)' ],
	backgroundColor: 'black',
	color: 'white',
	height: '200px',
	display: [ 'flex', 'flex', 'grid' ],
	gridTemplateColumns: '1fr 1fr',
	flexDirection: 'column',
	justifyContent: 'space-between',
	padding: '2rem',
	'span, p, a': {
		fontSize: '14px',
		textDecoration: 'none',
		color: 'white'
	},
	'.link': {
		fontSize: '36px',
		color: 'inherit',
		textDecoration: 'none',
		':hover': {
			color: 'gray',
			cursor: 'pointer'
		},
		h3: {
			fontSize: '36px',
			margin: 0
		}
	},
	'.left_foot, .right_foot': {
		'> p': {
			marginBottom: '0.5rem',
			fontSize: '18px'
		}
	},
	'.right_foot': {
		textAlign: 'right',
		display: 'block',
		marginTop: [ '-2rem' ]
	},
	'.bottom': {
		display: 'flex',
		justifyContent: 'space-between',
		gridColumnEnd: 'span 2'
	},
	'.last_foot': {
		alignSelf: 'flex-end',
		display: [ 'flex', 'flex', 'block' ],
		flexDirection: 'column',
		cursor: 'default'
	},
	span: {
		marginRight: '2rem',
		marginBottom: [ '0.8rem', '0.8rem', '' ]
	},
	'.boxes': {
		display: 'flex',
		alignItems: 'flex-end',
		justifyContent: 'flex-end',
		flexDirection: [ 'column', 'column', 'row' ],
		a: {
			width: '25px',
			height: '25px',
			marginLeft: '10px',
			marginBottom: '10px',
			img: {
				filter: 'invert(100%)',
				':hover': {
					filter: 'invert(70%)'
				}
			}
		}
	}
});
