import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import './responsiveness.css'
import './fonts/fonts.css'
import App from './App'
import { RecoilRoot } from 'recoil'
import { BrowserRouter, Route } from 'react-router-dom'

ReactDOM.render(
	<React.StrictMode>
		<BrowserRouter>
			<RecoilRoot>
				<Route path='/' render={(props) => <App {...props} />} key={Math.random()} />
			</RecoilRoot>
		</BrowserRouter>
	</React.StrictMode>,
	document.getElementById('root')
)
