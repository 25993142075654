/** @jsxRuntime classic /
/* @jsx jsx */
import { jsx } from '@emotion/react'
import facepaint from 'facepaint'
import img2 from '../media/office.png'
import Footer from '../components/Footer/Footer'
import gif1 from '../media/gif1.gif'
import gif2 from '../media/gif2.gif'
import gif3 from '../media/gif3.gif'
import logo from '../media/Logo_xlr+.svg'
import Button from '../components/Button'
import { useRecoilValue } from 'recoil'
import { languageState, text } from '../utils/state'

// RESPONSIVENESS SETTINGS
const breakpoints = [ 600, 800, 1300, 1800 ]
const mq = facepaint(breakpoints.map((bp) => `@media (min-width: ${bp}px)`))
const breakpoints2 = [ 600, 800, 1055, 1800 ]
const mq2 = facepaint(breakpoints2.map((bp) => `@media (min-width: ${bp}px)`))

export default function XLR() {
	const languageRecoil = useRecoilValue(languageState)
	const textRecoil = useRecoilValue(text)

	return (
		<div className='page xlr' css={xlrStyle}>
			<div css={top_grid}>
				<div css={grid_1_1}>
					{/* <h1>{textRecoil[languageRecoil].xlr.title_1}</h1> */}
					<img src={logo} alt='logo' style={{ width: '15vh' }} />
					<h2 style={{ marginTop: '44px' }}>{textRecoil[languageRecoil].xlr.top_1}</h2>
					<h2>{textRecoil[languageRecoil].xlr.top_2}</h2>
				</div>
				<div css={grid_1_2}>
					<img src={img2} alt='people working' />
				</div>
				<div css={grid_2_1}>
					<h1>{textRecoil[languageRecoil].xlr.title_2}</h1>
					<div>
						<h2 style={{ margin: 0 }}>{textRecoil[languageRecoil].xlr.services_1}</h2>
						<h2>{textRecoil[languageRecoil].xlr.services_2}</h2>
						<h2>{textRecoil[languageRecoil].xlr.services_3}</h2>
						<h2>{textRecoil[languageRecoil].xlr.services_4}</h2>
						<h2>{textRecoil[languageRecoil].xlr.services_5}</h2>
						<h2>{textRecoil[languageRecoil].xlr.services_6}</h2>
						<h2>{textRecoil[languageRecoil].xlr.services_7}</h2>
						<h2>{textRecoil[languageRecoil].xlr.services_8}</h2>
						<h2>{textRecoil[languageRecoil].xlr.services_9}</h2>
					</div>
				</div>
			</div>
			<div css={philosophy}>
				<h1>{textRecoil[languageRecoil].xlr.title_3}</h1>
				<div css={filo_grid}>
					<div className='filo_card'>
						<div>
							<img src={gif1} alt='gif' />
						</div>
						<h2>{textRecoil[languageRecoil].xlr.card1_title}</h2>
						<p>{textRecoil[languageRecoil].xlr.card1_body}</p>
					</div>
					<div className='filo_card'>
						<div>
							<img src={gif2} alt='gif' />
						</div>
						<h2>{textRecoil[languageRecoil].xlr.card2_title}</h2>
						<p>{textRecoil[languageRecoil].xlr.card2_body}</p>
					</div>
					<div className='filo_card filo_card3'>
						<div>
							<img src={gif3} alt='gif' />
						</div>
						<h2>{textRecoil[languageRecoil].xlr.card3_title}</h2>
						<p>{textRecoil[languageRecoil].xlr.card3_body}</p>
					</div>
				</div>
				<div css={last_bit}>
					<h2>{textRecoil[languageRecoil].xlr.contact_title}</h2>
					<a href='mailto: content@xlrestudio.com' target='_blank' rel='noreferrer'>
						<Button title={textRecoil[languageRecoil].xlr.contact_btn} borderColor='black' />
					</a>
				</div>
			</div>
			<Footer />
		</div>
	)
}

const xlrStyle = {
	minHeight: '100vh',
	width: '100vw',
	backgroundColor: '#ddffc9',
	transition: 'all linear 200ms',
	'h2, h4': {
		fontSize: '24px',
		margin: '1em 0 .5rem'
	},
	p: {
		fontSize: '18px'
	}
}

const top_grid = mq({
	display: 'grid',
	gridTemplateColumns: [ '', '', 'repeat(2, 1fr)' ]
})

const grid_1_1 = mq({
	minHeight: '50vh',
	borderBottom: '1px solid black',
	borderRight: '1px solid black',
	padding: [ '12vh 10vw 5vh', '15vh 6vh 5vh', '6vh 6vh' ],
	h1: {
		marginBottom: '1em',
		display: [ 'none', 'none', 'block' ]
	},
	p: {
		marginTop: '1rem  ',
		textAlign: 'justify'
	}
})

const grid_1_2 = mq({
	gridRowEnd: [ '', '', 'span 2' ],
	borderBottom: '1px solid black',
	display: 'flex',
	justifyContent: 'flex-stsart',
	img: {
		height: '100%',
		objectFit: 'cover'
	}
})

const grid_2_1 = mq({
	minHeight: '50vh',
	borderBottom: '1px solid black',
	borderRight: '1px solid black',
	display: [ 'block', 'block', 'block', 'grid' ],
	gridTemplateColumns: '1fr 1fr',
	padding: [ '7vh 10vw', '7vh 4vw', '7vh 4vw' ],
	h1: {
		margin: '-8px 0 1rem'
	},
	'div > p': {
		marginBottom: [ '0.3rem', '0.5rem', '0.5rem', '0.7rem' ]
	}
})

const philosophy = mq({
	backgroundColor: '#ddffc9',
	padding: [ '6vh 10vw', '6vh 4vw', '6vh 4vw' ],
	h1: {
		marginBottom: '1em'
	}
})

const filo_grid = mq2({
	display: 'flex',
	justifyContent: 'center',
	flexWrap: 'wrap',
	width: 'fit-content',
	maxWidth: '1200px',
	textAlign: 'center',
	margin: '0 auto 150px',
	'.filo_card': {
		width: '300px',
		margin: [ '0 2vw 4rem', '0 2vw 4rem', '0 2vw 4rem', '0 2vw' ],
		'> div': {
			height: '280px',
			boxSizing: 'border-box',
			margin: '0 10px',
			img: {
				width: '100%'
			}
		}
	}
})

const last_bit = {
	textAlign: 'center',
	marginBottom: '150px',
	h2: {
		marginBottom: '1em'
	}
}
