import { Switch, Route, Redirect } from 'react-router-dom'
// import Home from './Views/Home'
import About from './Views/About'
import Projects from './Views/Projects'
import XLR from './Views/XLR'
import Header from './components/Header/Header'
import { useTransition, animated } from 'react-spring'
import GoToTop from './components/GoToTop/GoToTop'
// import UnderConstruction from './components/UnderConstruction'

// TODO:
// - ADD FONTS ✅
// - MOBILE NAVBAR NOT WORKING ON PHONE ✅
// - CHANGE HOME PICS AUTOMATICALLY ✅
// - Home on mobile => FIX! ✅
// - xlr and plus imgs invert when not on home ✅
// - change pic on about ✅
// - add media icons ✅
// - Projects not working on mobile: change to hifi package ( dropdown )

// CAMBIOS
// - XLR btn on navbar => change on hover ✅
// - tweak toggle or make it circle ✅
// - add gifs + vertical on phone ✅
// - add footer to home ✅
// - current about is XLR+ ✅
// - add actual about ✅
// - make about responsive ✅
// - footer links + on hover ✅
// - contact is only open email ✅
// - transitioning routes ✅
// - change toggle to round ✅
// - fix font ✅
// - transition in language circle ✅

export default function App(props) {
  const transitions = useTransition(props.location, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 400 },
  })

  return (
    <div>
      <Route path='/' render={(props) => <Header {...props} />} />
      <GoToTop {...props} />
      {transitions((props, item) => {
        return (
          <animated.div style={props}>
            <Switch location={item}>
              <Route
                exact
                path='/about'
                render={(props) => <About {...props} />}
              />
              {/* <Route
              exact
              path="/projects"
              render={(props) => <UnderConstruction {...props}  />}
            /> */}
              <Route
                exact
                path='/home'
                render={(props) => <Projects {...props} />}
              />
              <Route
                exact
                path='/xlr+'
                render={(props) => <XLR {...props} />}
              />
              <Route
                exact
                path='/'
                render={() => {
                  return <Redirect to='/home' />
                }}
              />
            </Switch>
          </animated.div>
        )
      })}
    </div>
  )
}
