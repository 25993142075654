/** @jsxRuntime classic /
/* @jsx jsx */
import { jsx } from '@emotion/react'
import facepaint from 'facepaint'
import { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import Card from '../components/Card/Card'
import { languageState } from '../utils/state'
import { projectsStateES } from '../utils/projectsES'
import { projectsStateEN } from '../utils/projectsEN'
import { v4 as uuid } from 'uuid'
import Footer from '../components/Footer/Footer'

// RESPONSIVENESS SETTINGS
const breakpoints = [600, 800, 1300, 1800]
const mq = facepaint(breakpoints.map((bp) => `@media (min-width: ${bp}px)`))

export default function Projects() {
  const languageRecoil = useRecoilValue(languageState)
  const projectsES = useRecoilValue(projectsStateES)
  const projectsEN = useRecoilValue(projectsStateEN)
  const [projects, setProjects] = useState([])

  useEffect(() => {
    projectsES.forEach((proj1) => (proj1.id = uuid()))
    projectsEN.forEach((proj2) => (proj2.id = uuid()))
    let temp =
      languageRecoil === 'spanish' ? sort(projectsES) : sort(projectsEN)
    setProjects(temp)
  }, [languageRecoil, projectsES, projectsEN])

  const sort = (data) => {
    return data.sort((a, b) => {
      let num1 = Number(a.year.slice(0, 4))
      let num2 = Number(b.year.slice(0, 4))
      return num2 - num1
    })
  }

  return (
    <div className='page projects' css={projectsStyle}>
      <div css={top} />
      {/*  */}
      {projects.map((project, i) => (
        <div css={grid2} key={i}>
          <Card project={project} />
        </div>
      ))}
      {/*  */}
      <div css={grid1}>
        <div>
          {projects
            .filter((proj, i) => i % 2 === 0)
            .map((project, i) => (
              <Card project={project} key={i} />
            ))}
        </div>
        <div>
          {projects
            .filter((proj, i) => i % 2 === 1)
            .map((project, i) => (
              <Card project={project} key={i} />
            ))}
        </div>
      </div>
      <Footer />
    </div>
  )
}

const projectsStyle = mq({
  minHeight: '100vh',
  width: '100vw',
  backgroundColor: 'black',
  color: 'white',
  padding: ['12vh 0 0vh', '15vh 0 0vh', '6vh 0 0vh 70px'],
})

const top = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexWrap: 'wrap',
  padding: '0 10% 0 4.5%',
}

const grid1 = mq({
  display: ['none', 'none', 'none', 'grid'],
  gridTemplateColumns: '1fr 1fr',
  margin: [
    '70px auto 50px',
    '70px auto 50px',
    '70px auto 50px',
    '70px auto 50px',
  ],
  alignItems: 'baseline',
  flex: '1 1 auto',
  '> div': {
    margin: '0 auto',
  },
})

const grid2 = mq({
  display: ['block', 'block', 'block', 'none'],
  gridTemplateColumns: '1fr 1fr',
  margin: [
    '70px auto 50px',
    '70px auto 50px',
    '70px auto 50px',
    '70px auto 50px',
  ],
  alignItems: 'baseline',
  flex: '1 1 auto',
  '> div': {
    margin: '0 auto',
  },
})
