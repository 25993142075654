/** @jsxRuntime classic /
 * /* @jsx jsx */
import { jsx } from '@emotion/react'
import facepaint from 'facepaint'
import Map from '../components/Map/Map'
import { useRecoilValue } from 'recoil'
import { languageState, text } from '../utils/state'
import Button from '../components/Button'
import Footer from '../components/Footer/Footer'
import map from '../media/map.png'
import { NavLink } from 'react-router-dom'

// RESPONSIVENESS SETTINGS
const breakpoints = [600, 800, 1300, 1800]
const mq = facepaint(breakpoints.map((bp) => `@media (min-width: ${bp}px)`))

export default function Contact() {
  const languageRecoil = useRecoilValue(languageState)
  const textRecoil = useRecoilValue(text)

  return (
    <div className='page about' css={about}>
      <section style={{ margin: 0 }}>
        <h1 style={{ marginBottom: '72px' }}>
          {textRecoil[languageRecoil].about.title_1}
        </h1>
        <h2>{textRecoil[languageRecoil].about['1_1']}</h2>
        <h2>{textRecoil[languageRecoil].about['1_2']}</h2>
      </section>
      <section>
        <h1>{textRecoil[languageRecoil].about.title_2}</h1>
        <h2>{textRecoil[languageRecoil].about['2_1']}</h2>
        <h2>{textRecoil[languageRecoil].about['2_2']}</h2>
        <h2>{textRecoil[languageRecoil].about['2_3']}</h2>
      </section>
      <section>
        <h1>{textRecoil[languageRecoil].about.title_3}</h1>
        <h2>{textRecoil[languageRecoil].about['3_1']}</h2>
        <h2>{textRecoil[languageRecoil].about['3_2']}</h2>
        <h2>{textRecoil[languageRecoil].about['3_3']}</h2>
        <h2>{textRecoil[languageRecoil].about['3_4']}</h2>
        <h2>{textRecoil[languageRecoil].about['3_5']}</h2>
        <h2>{textRecoil[languageRecoil].about['3_6']}</h2>
        <h2>{textRecoil[languageRecoil].about['3_7']}</h2>
      </section>
      <section>
        <h1>{textRecoil[languageRecoil].about.title_4}</h1>
        <div className='about_grid_3'>
          <div>
            <p>Ayuntamiento de Madrid</p>
            <p>IFEMA</p>
            <p>Comunidad de Madrid</p>
            <p>Instituto de las Mujeres</p>
            <p>Ministerio de Igualdad</p>
            <p>Ministerio de Cultura</p>
            <p>Madrid Destino</p>
            <p>Conde Duque</p>
            <p>Matadero</p>
            <p>Teatro Español</p>
            <p>Palacio de Cibeles</p>
            <p>Museo Nacional Del Prado</p>
            <p>Museo Nacional Thyssen Bornemisza</p>
          </div>
          <div>
            <p>Subdirección General de Museos</p>
            <p>Fundación Montemadrid</p>
            <p>Fundación Oxfam Intermón</p>
            <p>La Casa Encendida</p>
            <p>Embajada de Francia en España</p>
            <p>Embajada Argentina en Madrid</p>
            <p>Adobe Systems Ibérica</p>
            <p>The Brownie Film Company</p>
            <p>Jack Morton World Wide</p>
            <p>PF Global LTD</p>
            <p>Hermès International</p>
            <p>Planet Events</p>
            <p>Acciona Producciones y Diseño</p>
          </div>
          <div>
            <p>Concert Live Tour</p>
            <p>Globally Eventos y Comunicación</p>
            <p>Enri Mur Management</p>
            <p>Esmerarte Industrias Creativas</p>
            <p>Maraworld (FIB)</p>
            <p>Riff Producciones</p>
            <p>Billionaire Dubai/Riyadh/Doha/Cerdeña</p>
            <p>Asociación de Creadores de la Moda Española</p>
            <p>David Delfín</p>
            <p>Jesús del Pozo</p>
            <p>Brief Festival</p>
            <p>Sra Rushmore</p>
            <p>Manuel Carrasco</p>
            <p>Alejandro Sanz</p>
          </div>
          <div>
            <p>Amaral</p>
            <p>Robe Iniesta</p>
            <p>Vetusta Morla</p>
            <p>Los 40 Principales</p>
            <p>Sidonie</p>
            <p>Sidecars</p>
            <p>León Benavente</p>
            <p>Leiva</p>
            <p>Jose Luis Perales</p>
            <p>Jorge Drexler</p>
            <p>Russian Red</p>
            <p>Y muchos más...</p>
          </div>
        </div>
        <NavLink to='/home' style={{ padding: '0 18% 0 4%' }}>
          <Button
            title={textRecoil[languageRecoil].about.button_title}
            color='white'
          />
        </NavLink>
      </section>
      <section>
        <h1>{textRecoil[languageRecoil].about.title_5}</h1>
        <div className='about_grid_3'>
          <div>
            <p className='bold'>Maxi Gilbert</p>
            <p>{textRecoil[languageRecoil].about.team1}</p>
            <p>Diseño de Escenografía</p>
            <p>
              <a
                href='mailto: mx@xlrestudio.com'
                target='_blank'
                rel='noreferrer'
              >
                mx@xlrestudio.com
              </a>
            </p>
          </div>
          <div>
            <p className='bold'>Manuela Burns</p>
            <p>{textRecoil[languageRecoil].about.team3}</p>
            <p>
              <a
                href='mailto: manuela@xlrestudio.com'
                target='_blank'
                rel='noreferrer'
              >
                manuela@xlrestudio.com
              </a>
            </p>
          </div>
          <div>
            <p className='bold'>Susana Martínez Allende</p>
            <p>{textRecoil[languageRecoil].about.team4}</p>
            <p>
              <a
                href='mailto: susana@xlrestudio.com'
                target='_blank'
                rel='noreferrer'
              >
                susana@xlrestudio.com
              </a>
            </p>
          </div>
          <div>
            <p className='bold'>Vanesa Guerrero</p>
            <p>{textRecoil[languageRecoil].about.team5}</p>
            <p>
              <a
                href='mailto: administracion@xlrestudio.com'
                target='_blank'
                rel='noreferrer'
              >
                administracion@xlrestudio.com
              </a>
            </p>
          </div>
        </div>
      </section>
      <section style={{ margin: '0 0 -5px 0' }}>
        <h1>{textRecoil[languageRecoil].about.title_6}</h1>
        <img src={map} alt='map' />
      </section>
      <Map />
      <Footer />
    </div>
  )
}

const about = mq({
  minHeight: '100vh',
  width: '100vw',
  backgroundColor: 'black',
  color: 'white',
  paddingTop: ['80px', '100px', '60px'],
  boxSizing: 'border-box',
  section: {
    marginTop: '150px',
    'h1, h2': {
      padding: '0 18% 0 4%',
      marginBottom: '.75em',
    },
    p: {
      fontSize: '18px',
      padding: '0 18% 0 4%',
      marginBottom: '1em',
    },
  },
  '.about_grid_3': {
    padding: '0 10% 50px 4%',
    display: 'grid',
    width: ['100%%', '100%', '70%'],
    gridTemplateColumns: [
      '1fr',
      'repeat(2, 1fr)',
      'repeat(2, 1fr)',
      // 'repeat(3, 1fr)',
    ],
    div: {
      marginBottom: '1.5em',
    },
    p: {
      margin: 0,
      padding: 0,
      fontSize: '18px',
      marginBottom: '.2rem',
    },
    a: {
      color: 'white',
    },
  },
})
