/** @jsxRuntime classic /
/* @jsx jsx */
import { jsx } from "@emotion/react";

export default function Button(props) {
  const style = {
    backgroundColor: "transparent",
    outline: "none",
    border: `1px solid ${props.borderColor || "white"}`,
    borderRadius: "100px",
    padding: "0 32px",
    height: "64px",
    color: props.color,
    cursor: "pointer",
  };

  return <button css={style}>{props.title}</button>;
}
