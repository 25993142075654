/** @jsxRuntime classic /
/* @jsx jsx */
import { jsx } from '@emotion/react'
import facepaint from 'facepaint'
import { useState } from 'react'
import minus from '../../media/minus_white.svg'
import plus from '../../media/plus_white.svg'
import { useRecoilValue } from 'recoil'
import { languageState, text } from '../../utils/state'
import Carousel from './Carousel.js'

// RESPONSIVENESS SETTINGS
const breakpoints = [600, 800, 1300, 1800]
const mq = facepaint(breakpoints.map((bp) => `@media (min-width: ${bp}px)`))

export default function Card({ project }) {
  const textRecoil = useRecoilValue(text)
  const languageRecoil = useRecoilValue(languageState)

  const [expanded, setExpanded] = useState(false)

  const plus_minus_wrapper = mq({
    transition: 'all 0.3s linear',
    img: {
      filter: 'invert(100%)',
      width: ['25px', '30px'],
      transition: 'all 0.3slineart',
    },
    '.min': {
      marginRight: ['-25px', '-30px', '-30px'],
    },
  })

  return (
    <div css={card}>
      <div>
        <Carousel images={project.images} />
      </div>
      <div>
        <div
          className='top_wrapper'
          onClick={() => {
            setExpanded(!expanded)
          }}
          style={{ cursor: 'pointer' }}
        >
          <h2>{project.title}</h2>
          <div css={plus_minus_wrapper}>
            <img
              src={minus}
              alt='minus'
              className='min'
              style={{ opacity: !expanded ? 0 : 1 }}
            />
            <img src={plus} alt='plus' style={{ opacity: expanded ? 0 : 1 }} />
          </div>
        </div>
        <div
          css={content}
          style={{
            maxHeight: !expanded ? 0 : '500px',
            transition: !expanded
              ? 'max-height 0.3s ease-out'
              : 'max-height 0.3s ease-in',
          }}
        >
          <p className='m-t24'>{`${textRecoil[languageRecoil].projects.card1}: ${project.client}`}</p>
          <p>{`${textRecoil[languageRecoil].projects.card2}: ${project.location}`}</p>
          <p>{`${textRecoil[languageRecoil].projects.card3}: ${project.year}`}</p>

          {project.description.includes('*') ? (
            <div css={linkThing} className='m-t24'>
              <p key='1'>{project.description.split('*')[0]}</p>
              <p key='3'>
                <a
                  style={{ display: 'inline', color: 'white' }}
                  href={project.description.split('*')[1]}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {project.description.split('*')[2]}
                </a>
              </p>
              <p style={{ display: 'inline' }} key='4'>
                {project.description.split('*')[3]}
              </p>
            </div>
          ) : (
            [
              <p className='m-t24' key='1'>
                {project.description.split(';')[0]}
              </p>,
              <p key='2'>{project.description.split(';')[1]}</p>,
            ]
          )}
          {project.video && (
            <a
              className='m-t24 gray'
              href={project.video}
              target='_blank'
              rel='noreferrer'
            >
              Video
            </a>
          )}
          <p className='m-t24 gray credits'>{project.credits}</p>
        </div>
      </div>
    </div>
  )
}

const card = mq({
  margin: ['0 auto 40px', '0 auto 40px', '0 auto 40px', '0 auto 40px'],
  width: ['90vw', '80vw', '85vw', '40vw'],
  maxWidth: '650px',
  transition: 'all linear 400ms',
  '> img': {
    width: '100%',
    borderRadius: '8px',
    border: 'none',
    marginBottom: '24px',
  },
  '.top_wrapper': {
    display: ['grid', 'flex'],
    justifyContent: 'space-between',
    gridTemplateColumns: '10fr 1fr',
  },
  '.m-t24': {
    marginTop: '24px',
    textAlign: 'justify',
  },
})

const linkThing = {
  display: 'flex',
  p: {
    marginRight: '4px',
  },
}

const content = {
  overflow: 'hidden',
  a: { display: 'block' },
  '.credits': {
    fontSize: '17px',
  },
}
